import React from "react";
import "./Education.css";
import EducationCard from "../../components/educationCard/EducationCard";
import { educationInfo } from "../../portfolio";

export default function Education() {
  return (
    <div className="education-section" id="education">
      <h1 className="education-heading">Education</h1>
      <div className="education-card-container">
        {educationInfo.schools.map((school, index) => (
          <EducationCard school={school} key={index} />
        ))}
      </div>
    </div>
  );
}
